/* Owl-carousel */

.owl-carousel {
    img {
        border-radius: 4px;
    }
    &.full-width {
        .owl-nav {
            position: absolute;
            top: 30%;
            width: 100%;
            margin-top: 0;
            .owl-prev,
            .owl-next {
                background: transparent;
                color: $white;
                i {
                    &:before {
                        width: 60px;
                        height: 60px;
                        background: rgba($black, .5);
                        border-radius: 100%;
                        font-size: 1.875rem;
                        font-weight: bold;
                        line-height: 2;
                        display: block;
                    }
                }
            }
            .owl-prev {
                float: left;
            }
            .owl-next {
                float: right;
            }
        }
        .owl-dots {
            margin-top: 1rem;
        }
    }
    .item-video {
        width: 200px;
        height: 200px;
    }
}